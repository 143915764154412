import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import PatentProductRankingDetail from "./patent-product-ranking-detail";

const PatentAnalysisMenu = ({
  anchorEl,
  open,
  handleClose,
  patentProduct,
  searchId,
  choosenClaimIndex,
}) => {
  const [openRankingDetail, setOpenRankingDetail] = useState(false);

  const handleOpenRankingDetail = () => {
    if (patentProduct && searchId) {
      setOpenRankingDetail(true);
      // We don't close the menu here, allowing it to remain open
    } else {
      console.error("Patent product or search ID is missing");
    }
  };

  const handleCloseRankingDetail = () => {
    setOpenRankingDetail(false);
    handleClose(); // Close the menu
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose} // This will allow manual menu closing
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleOpenRankingDetail}>
          Relevancy Analysis
        </MenuItem>
        {/* <MenuItem onClick={handleClose}></MenuItem>
        <MenuItem onClick={handleClose}></MenuItem> */}
      </Menu>

      {/* Show PatentProductRankingDetail without closing the menu */}
      {patentProduct && searchId && (
        <PatentProductRankingDetail
          open={openRankingDetail}
          handleClose={handleCloseRankingDetail}
          patentProduct={patentProduct}
          searchId={searchId}
          choosenClaimIndex={choosenClaimIndex}
        />
      )}
    </>
  );
};

export default PatentAnalysisMenu;
