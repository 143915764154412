import { useState, useMemo, useCallback, useContext } from 'react';
import { userService } from "../services/user.service";
import { searchService } from "../services/search.service";
import { StatusUpdatesContext } from "../services/search.status.updates.context";
import { showErrorMsg } from "../services/event-bus.service";
import PatentValidator from "../services/validation.service";

export const useAssigneeSearch = () => {
  // Form states
  const [searchAssignee, setSearchAssignee] = useState({
    query: "",
    assignee: "",
    startDate: null,
    endDate: null,
    showStartDatePicker: false,
    showEndDatePicker: false,
  });

  // List management states
  const [assignees, setAssignees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  // Search processing states
  const [isLoading, setIsLoading] = useState(false);
  const [validPatents, setValidPatents] = useState([]);
  const [searchAvailable, setSearchAvailable] = useState(0);
  const [sumConfirmPatents, setSumConfirmPatents] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { initiateStatusUpdates } = useContext(StatusUpdatesContext);

  const filteredAssignees = useMemo(() => {
    const searchTermLower = searchTerm.toLowerCase();

    const filtered = assignees.filter(
      (item) =>
        item.assignee.toLowerCase().includes(searchTermLower) ||
        item.title.toLowerCase().includes(searchTermLower) ||
        item.publication_number.toLowerCase().includes(searchTermLower)
    );

    if (filtered.length > 0) {
      return filtered;
    }

    // If we have a search term but no results, try searching with each
    // progressively shorter substring until we find results
    for (let i = searchTermLower.length - 1; i > 0; i--) {
      const partialTerm = searchTermLower.slice(0, i);
      const partialFiltered = assignees.filter(
        (item) =>
          item.assignee.toLowerCase().includes(partialTerm) ||
          item.title.toLowerCase().includes(partialTerm) ||
          item.publication_number.toLowerCase().includes(partialTerm)
      );
      if (partialFiltered.length > 0) {
        return partialFiltered;
      }
    }

    return assignees; // If no partial matches found, return all
  }, [searchTerm, assignees]);

  const handleAssigneeChange = useCallback((field, value) => {
    setSearchAssignee((prevSearch) => ({
      ...prevSearch,
      [field]: value,
    }));
  }, []);

  const handleDatePickerToggle = useCallback((field) => {
    setSearchAssignee((prev) => ({
      ...prev,
      showStartDatePicker: field === "showStartDatePicker" ? !prev.showStartDatePicker : false,
      showEndDatePicker: field === "showEndDatePicker" ? !prev.showEndDatePicker : false,
    }));
  }, []);
  const isFormValid = useMemo(() => {
    return searchAssignee.assignee && searchAssignee.startDate && searchAssignee.endDate;
  }, [searchAssignee]);
  const handleSubmit = useCallback(async () => {
    if (!isFormValid) {
      showErrorMsg("Please fill out all required fields.");
      return;
    }
    setIsFormSubmitted(true);

    try {
      setIsLoading(true);
      setSearchTerm("");
      setSelectedItems([]);
      setCurrentPage(1);
      setValidPatents([]);
      setShowConfirm(false);

      const formatDate = (date) => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}${month}${day}`;
      };

      const formattedSearch = {
        query: searchAssignee.query,
        assignee: searchAssignee.assignee,
        before: formatDate(searchAssignee.endDate),
        after: formatDate(searchAssignee.startDate),
      };

      const results = await userService.companySearchPatents(formattedSearch);
      // setSearchAssignee({
      //   query: "",
      //   assignee: "",
      //   startDate: null,
      //   endDate: null,
      //   showStartDatePicker: false,
      //   showEndDatePicker: false,
      // });
      setAssignees(results);
      setIsFormSubmitted(false);
    } catch (error) {
      console.error("Failed to submit search:", error);
      showErrorMsg("Failed to fetch results");
    } finally {
      setIsLoading(false);
    }
  }, [searchAssignee, isFormValid]);
  const handleSelectAll = useCallback(() => {
    const availableItemIds = filteredAssignees.map(item => item.publication_number);
    const allAreSelected = availableItemIds.every(id => selectedItems.includes(id));

    if (allAreSelected) {
      setSelectedItems([]); // Unselect all
    } else {
      setSelectedItems(availableItemIds); // Select all available items
    }
  }, [selectedItems, filteredAssignees]);
  const handleSearchSelectedPatentClick = useCallback(() => {
    if (selectedItems.length === 0) {
      showErrorMsg("No items selected. Please select at least one item.");
      return;
    }
    // Find the publication numbers from assignees that match selectedItems
    const selectedPatents = assignees
      .filter(item => selectedItems.includes(item.publication_number))
      .map(item => item.publication_number);

    return selectedPatents;
  }, [selectedItems, assignees]);

  const handleConfirmSearch = useCallback(async () => {
    setShowConfirm(false);
    setIsLoading(true);

    try {
      await searchService.startSearch(
        validPatents,
        initiateStatusUpdates,
        selectedClaim
      );
    } catch (error) {
      console.error("Error during search:", error);
      showErrorMsg("Failed to start search.");
    } finally {
      setIsLoading(false);
    }
  }, [validPatents, initiateStatusUpdates, selectedClaim]);

  return {
    // States
    searchAssignee,
    assignees: filteredAssignees,
    searchTerm,
    selectedItems,
    currentPage,
    itemsPerPage,
    isLoading,
    showConfirm,
    sumConfirmPatents,
    searchAvailable,
    isFormSubmitted,
    pageCount: Math.ceil(filteredAssignees.length / itemsPerPage),

    // Handlers
    handleAssigneeChange,
    handleDatePickerToggle,
    handleSubmit,
    setSearchTerm,
    setSelectedItems,
    setCurrentPage,
    setItemsPerPage,
    handleSearchSelectedPatentClick,
    handleSelectAll,

  };
};