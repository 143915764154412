import { showErrorMsg, showSuccessMsg } from "./event-bus.service";
import { trackGoogleAnalyticsEvent, EVENT_CATEGORIES } from './analytics.service';



const BASE_URL = process.env.REACT_APP_API_URL;
// const IS_TEST_MODE = false;
const IS_TEST_MODE = process.env.REACT_APP_TEST_MODE === 'true' || false;

export async function validatePatent(arrInputs) {
  console.log("validatePatent arrInputs:", arrInputs);
  const validatePatentUrl = `${BASE_URL}/validate_patents`;


  try {
    console.log("start valiadatePatent");
    const response = await fetch(validatePatentUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ patent_numbers: arrInputs })
    });
    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`Failed to valiadate Patent: ${errorText}`);
      throw new Error(`Failed to valiadate Patent: ${errorText.error}`);
    }

    const data = await response.json();
    console.log('valiadate Patent response received:', data);
    // showSuccessMsg(`valiadate Patent response received`);
    return data;

  } catch (error) {
    showErrorMsg(`Failed to valiadate Patent: ${error.message}`);
    throw error;
  }

}

export async function startSearch(arrInputs, initiateStatusUpdates, onlyFirstClaimSelected, claimText, claimName) {

  console.log("arrInputs START SEARCH:", arrInputs);
  console.log("Runing search in test mode: ", IS_TEST_MODE);
  console.log("start search!!!", claimText)
  const patentsNumsArr = arrInputs.map((patent_number) => {

    if (!IS_TEST_MODE) {
      // Send Google Analytics event only when not in test mode
      trackGoogleAnalyticsEvent(EVENT_CATEGORIES.PATENT_SEARCH_START, patent_number);
    }

    return {
      patent_number: patent_number,
      test: IS_TEST_MODE,
      claim_indexes: onlyFirstClaimSelected === 1 ? [1] : [],
      ...(claimText ? { custom_claim: { text: claimText } } : {}),
      claim_names: claimName
    };
  });

  console.log("patentsNumsArr:", patentsNumsArr);
  const startSearchUrl = `${BASE_URL}/start_search`;
  console.log("startSearchUrl*******:");
  console.log('Starting search with query:', patentsNumsArr);
  try {
    console.log("try startSearch");
    const response = await fetch(startSearchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ queries: patentsNumsArr })
    });

    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`Failed to start search: ${errorText}`);
      throw new Error(`Failed to start search: ${errorText.error}`);
    }

    const data = await response.json();
    console.log('Search response received:', data);
    showSuccessMsg(`Search started. <a href="/archive">Click here to see results</a>`);
    console.log('Calling initiateStatusUpdates()');
    initiateStatusUpdates();
    return data;

  } catch (error) {
    showErrorMsg(`Failed to start search: ${error.message}`);
    throw error;
  }
}





export async function explainResult(searchId, patentProduct, choosenClaimIndex) {
  console.log("searchId:", searchId, "patentProduct:", patentProduct, "choosenClaimIndex:", choosenClaimIndex)
  const query = {
    search_id: searchId,
    claim_index: choosenClaimIndex,
    uid: patentProduct.uid,
  }

  const explainResultUrl = `${BASE_URL}/explain_result`;

  try {
    const response = await fetch(explainResultUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(query)
    });

    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`Failed to explain Result: ${errorText.error}`);
      throw new Error(`Failed to explain Result: ${errorText.error}`);
    }

    const data = await response.json();
    console.log('Results response received:', data);
    showSuccessMsg('Results fetched successfully');
    return data;

  } catch (error) {
    showErrorMsg(`Failed to 'Explain result: ${error.message}`);
    throw error;
  }
}
export async function getPatentResults(searchId, onUpdate, onError) {
  console.log("Fetching archive with searchId:", searchId);
  const getResultUrl = `${BASE_URL}/result`;

  try {
    const response = await fetch(getResultUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ search_id: searchId })
    });

    const data = await response.json();
    console.log('Received archive result:', data);
    onUpdate(data);
    if (!response.ok) {
      showErrorMsg(`Failed to fetch archive: ${data.error}`);
      throw new Error(`Failed to fetch archive: ${data.error}`);
    }

  } catch (error) {
    onError(`Error fetching archive: ${error.message}`);
    showErrorMsg(`Error fetching archive: ${error.message}`);
  }
}

export async function getUserResults(term) {
  try {
    const response = await fetch(`${BASE_URL}/user_results`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`Failed to fetch user results: ${errorText.error}`);
      throw new Error(`Failed to fetch user results: ${errorText.error}`);
    }

    const data = await response.json();
    console.log("User results data:", data.archive_results);

    let filteredData = data.archive_results;
    if (term !== "") {
      const searchTerm = term.toLowerCase();
      filteredData = data.archive_results.filter(item =>
        item.search_id.includes(searchTerm) ||
        item.patent_number.toLowerCase().includes(searchTerm) ||
        item.description.toLowerCase().includes(searchTerm) ||
        item.status.toLowerCase().includes(searchTerm) ||
        item.updated_at.toLowerCase().includes(searchTerm)
      );
    }

    // Sort filteredData by updated_at field from newest to oldest
    filteredData.sort((a, b) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      return dateB - dateA;
    });

    // showSuccessMsg('User results fetched successfully');
    return filteredData;

  } catch (error) {
    showErrorMsg(`Failed to fetch user results: ${error.message}`);
    throw error;
  }
}

export async function updateUserRating(searchId, patentUid, rating) {
  const userEmail = localStorage.getItem("userEmail");
  const updateUserRatingUrl = `${BASE_URL}/user_rating`;

  console.log('Updating user rating for searchId:', searchId, 'patentUid:', patentUid, 'rating:', rating);
  try {
    const response = await fetch(updateUserRatingUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ search_id: searchId, uid: patentUid, rating: rating, user: userEmail })
    });

    if (!response.ok) {
      const errorText = await response.json();
      showErrorMsg(`Failed to update rating: ${errorText.error}`);
      throw new Error(`Failed to update rating: ${errorText.error}`);
    }

    const data = await response.json();
    console.log('Rating update response received:', data);
    // showSuccessMsg('Rating updated successfully');
    return data;

  } catch (error) {
    showErrorMsg(`Failed to update rating: ${error.message}`);
    throw error;
  }
}


export const searchService = {
  validatePatent,
  startSearch,
  getUserResults,
  getPatentResults,
  updateUserRating,
  explainResult
};
