import { PublicClientApplication } from '@azure/msal-browser';
const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`, // Microsoft client ID
    authority: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?`,
    redirectUri: `${process.env.REACT_APP_AUTH_REDIRECT}`, //redirect URI
  },
  cache: {
    cacheLocation: 'localStorage', // or 'sessionStorage'
    storeAuthStateInCookie: false,
  },
};
const msalInstance = new PublicClientApplication(msalConfig);
const initializeMsal = async () => {
  try {
    await msalInstance.initialize();
    console.log('MSAL initialized successfully');
  } catch (error) {
    console.error('MSAL initialization failed:', error);
  }
};
export { msalInstance, initializeMsal };