import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../services/user.service";
import { showErrorMsg } from "../services/event-bus.service";

const Home = ({ onAgree }) => {
  const navigate = useNavigate();
  const [isLoding, setIsLoading] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [checkAgreement, setCheckAgreement] = useState(false);
  const [openTerms, setOpenTerms] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [agreement, setAgreement] = useState({
    version: 0,
    date: "",
    text: "",
  });
  const storageToken = localStorage.getItem("token");

  useEffect(() => {
    if (!storageToken) {
      navigate("/");
    }
  }, [storageToken]);
  useEffect(() => {
    const fetchData = async () => {
      const userName = localStorage.getItem("userName");

      if (userName) {
        await fetchLoggedInUser();
        await fetchAgreementText();
      } else {
        navigate("/");
      }

      setIsLoading(false);
    };

    fetchData();
  }, []);
  const fetchLoggedInUser = async () => {
    try {
      const loggedIn = await userService.getLoggedInUser();
      console.log("loggedIn!!!!", loggedIn);
      setLoggedInUser(loggedIn);
    } catch (error) {
      console.error("Failed to load logged-in user:", error);
    }
  };

  const fetchAgreementText = async () => {
    try {
      const response = await fetch("/agreement.json");
      const data = await response.json();
      setAgreement(data);
      if (loggedInUser.agreement <= data.version) {
        onAgree();
      }
    } catch (error) {
      console.error("Failed to load agreement text:", error);
    }
  };

  const handleClick = () => {
    navigate("/patent-search");
  };
  const handleClickClaimSearch = () => {
    navigate("/claim-search");
  };
  const handleClickSearch = () => {
    navigate("/search");
  };
  const updateAgreementVersion = async (agreementVersion) => {
    try {
      await userService.updateUserAgreement(agreementVersion);
      setCheckAgreement(true);
      onAgree();
    } catch (error) {
      showErrorMsg("Failed to update agreement:", error);
    }
  };

  const handleCloseTerms = () => {
    setOpenTerms(false);
    updateAgreementVersion(agreement.version);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  if (isLoding)
    return (
      <Box
        sx={{
          marginTop: "200px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress sx={{ color: "primary.main" }} />
        <Typography variant="h6" sx={{ marginTop: "10px", color: "gray" }}>
          Almost there...
        </Typography>
      </Box>
    );

  return (
    <>
      {/* Terms and Conditions Dialog */}
      {agreement.version > (loggedInUser.agreement || 0) && (
        <Dialog
          open={openTerms}
          disableEscapeKeyDown
          onClose={null}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle sx={{ color: "primary.main" }}>
            Agree To Sales Agreement
          </DialogTitle>
          <DialogContent dividers>
            <Box sx={{ maxHeight: 300, overflowY: "auto" }}>
              <Typography variant="body1" paragraph>
                {agreement.text || "Loading terms..."}
              </Typography>
            </Box>
          </DialogContent>

          <Divider />

          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color="primary"
                    sx={{ marginLeft: "16px" }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      margin: "10px 0",
                      color: "black",
                    }}
                  >
                    I have read and agree to the terms of the agreement. To
                    continue using PioneerIP, please read the entire agreement
                    and click the 'Agree' button.
                  </Typography>
                }
              />
            </Box>

            <Button
              variant="contained"
              onClick={handleCloseTerms}
              disabled={!isChecked} // Disabled until the checkbox is checked
              sx={{
                backgroundColor: isChecked ? "primary.main" : "gray",
                color: isChecked ? "white" : "darkgray",
                "&:hover": {
                  backgroundColor: isChecked ? "primary.dark" : "gray",
                },
              }}
            >
              AGREE
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* Main Content */}
      <Container
        maxWidth="lg"
        sx={{
          mt: "220px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "250px",
        }}
      >
        {loggedInUser && (
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box>
              <Button
                onClick={handleClick}
                variant="contained"
                color="primary"
                sx={{
                  padding: "20px 40px",
                  width: "fit-content",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  color: "#fff",
                  height: "120px",
                  width: "200px",
                }}
              >
                <Typography sx={{ fontSize: "1rem" }}>
                  Patent <br /> Infringement <br /> Search
                </Typography>
              </Button>
              <Typography
                sx={{
                  fontSize: "1rem",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                Search for products on the market that are similar to the patent
                claim
              </Typography>
            </Box>{" "}
            <Box>
              <Button
                onClick={handleClickClaimSearch}
                variant="contained"
                color="primary"
                sx={{
                  padding: "20px 40px",
                  width: "fit-content",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  color: "#fff",
                  height: "120px",
                  width: "200px",
                }}
              >
                <Typography sx={{ fontSize: "1rem" }}>
                  Application
                  <br />
                  Search <br />
                </Typography>
              </Button>
              <Typography
                sx={{
                  fontSize: "1rem",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                Search for products on the market that are similar to the
                proposed application claim
              </Typography>
            </Box>
            {/* <Box>
              <Button
                onClick={handleClickSearch}
                variant="contained"
                color="primary"
                sx={{
                  padding: "20px 40px",
                  width: "fit-content",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  color: "#fff",
                  height: "120px",
                  width: "200px",
                }}
              >
                <Typography sx={{ fontSize: "1rem" }}>
                  {" "}
                  Infringement <br /> Search <br /> By
                  <br /> Assignees
                </Typography>
              </Button>
              <Typography
                sx={{
                  fontSize: "1rem",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                Search for products on the market that are similar to the patent
                claim
              </Typography>
            </Box>{" "} */}
          </Box>
        )}
      </Container>
    </>
  );
};

export default Home;
