/*
This provider delivers status updates on active searches and informs users of their remaining search tokens. 
The process is triggered by several events: 
   when a user starts a new search, 
   navigates between pages, 
   or reopens the browser after closing it.
The process begins with a simple query to the server to check if any active searches exist. 
If there are no active searches, the system only checks the number of tokens the user has left, 
and the process stops there. 
If active searches are found, the list of active search IDs is updated, 
and a WebSocket connection is established with the server to listen for notifications.
The server sends only search completion notifications. 
Each notification includes an updated list of remaining active searches. 
If multiple searches are active, several completion notifications may be received. 
Each time a notification is received, the list of active searches is updated. 
This list is accessible to various consumers within the application.
Additionally, with each search completion notification, 
the number of remaining tokens is updated and made available to the application's consumers as well.
*/
import { showErrorMsg } from "./event-bus.service";
import React, { createContext, useState, useEffect, useRef } from 'react';
import { userService } from '../services/user.service';
import { useLocation } from 'react-router-dom';

export const StatusUpdatesContext = createContext(); 

export const StatusUpdatesProvider = ({ children }) => {
  const location = useLocation();
  const [loggedInUser, setloggedInUser] = useState();
  const [userSearchTotalQuota, setTotalQuota] = useState(50);
  const [userSearchLeftsQuota, setLeftsQuota] = useState(0);  
  const [activeSearchesRecord, setActiveSearchesRecord] = useState([]);  
  const webSocketRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_API_URL;
  const WEBֹ_SOCKETֹֹֹ_URL = process.env.REACT_APP_WEBSOCKET_URL;
  let webSocketkeepAliveInterval; 

  useEffect(() => {
    if(isCurrentlyUserLoggedIn() == false) return; //When a user is not logged in there is no tokens to get from the server
    updateUserTokens(); // Update the user tokens quota every page change
    initiateStatusUpdates();
  }, [location]);


   // Ask for active searches, and if there are, start a webSoclet and wait for server push notification.
   const initiateStatusUpdates = () => {
    if(isCurrentlyUserLoggedIn() == false) return; //When a user is not logged in there is no tokens to get from the server

    console.log("fetching active_searches");
    const activeSearchesGetUrl = `${BASE_URL}/active_searches`;
    //'Sleeping for a 4 seconds: wating for the search task to start on the server before asking the server if there are any active searches.
    setTimeout(() => {
      fetch(activeSearchesGetUrl, {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      })
        .then((response) => {
          if (!response.ok) {
            const errorText =  `Failed to fetch active_searches: ${response.json()}`;
            showErrorMsg(errorText);            
          }
          console.log(response);
          return response.json()})
        .then((data) => {
          if (data.active_searches) {
              console.log("data.hasActiveSearches = true");
              console.log(data);                            
              openWebSocket(); 
              updateUserTokens();           
          } else {
              console.log("data.hasActiveSearches = false");              
              closeWebSocket();
          }
        })
        .catch((error) => {
          const errorMessage = `Error checking active searches: ${error}`;
          console.error(errorMessage);          
        });


    }, 4000);
  };

  // Open a WebSocket
  const openWebSocket = () => {
    if (webSocketRef.current) {
      console.log('WebSocket already connected');
      return;
    }
    console.log('Opening a WebSocket');
    const ws = new WebSocket(`${WEBֹ_SOCKETֹֹֹ_URL}?token=${localStorage.getItem('token')}`);

    //Register to webSoket events:
    ws.onopen = handleWebSocketOpen; 
    ws.onmessage = handleWebSocketMessageArrived;  
    ws.onclose = handleWebSocketCloseEvent;
    ws.onerror = handleWebSocketErrorEvent;
    webSocketRef.current = ws;
  };


  //When the webSocket connected to the server (When open), this method will be called:
  function handleWebSocketOpen(){
    console.log('WebSocket connected');        
    
    //Keep alive messages (30 seonds) - so the webSocket will not disconnect with no activity
    webSocketkeepAliveInterval = setInterval(() => {
      if (webSocketRef.current.readyState === WebSocket.OPEN) {
        webSocketRef.current.send('ping'); 
      }
    }, 30000);
  }

  //When the webSocket recived a new message from the server
  function handleWebSocketMessageArrived(messageArrivedEvent) {
    console.log('********* WebSocket message arrived ***************');
    try {      
      const messageData = messageArrivedEvent.data;           
      if (typeof messageData === 'string' && messageData.trim().startsWith('{')) {        
        const data = JSON.parse(messageArrivedEvent.data);
        console.log('Message type: ', data.message.type)
        console.log(data);         
        if (data.message.updated_record) {
          console.log('---  Search record arrived   ---'); 
          setActiveSearchesRecord(prevRecords => {
            console.log(prevRecords);
            const index = prevRecords.findIndex(record => record.search_id === data.message.updated_record.search_id);
            if (index !== -1) {
              // If the object already exists, replace it
              const updatedRecords = [...prevRecords];
              updatedRecords[index] = data.message.updated_record;
              return updatedRecords;
            } else {
              // If the object doesn't exist, add it
              return [...prevRecords, data.message.updated_record];
            }
          });
        }    
        updateUserTokens();
      } else {
          //This is the keep alive response:
          console.log('keep-alive message received, Ignoring the message content');
      }
    } catch (error) {
        console.error('Error handling websocket message arrived from the server:', error);
    }
    console.log('---------- Done handling the WebSocket message -------------')
  }

  //When the webSocket is closed, The event will call this method
  function handleWebSocketCloseEvent(){
    clearInterval(webSocketkeepAliveInterval);
    console.log('WebSocket disconnected');
    webSocketRef.current = null;    
    updateUserTokens();   
  }

  //When an error accured suring the webSocket connection, this function will be called
  function handleWebSocketErrorEvent(errorEvent){
    console.error('WebSocket error:', errorEvent);
    console.log(`WebSocket closed with code: ${errorEvent.code} and reason: ${errorEvent.reason}`);      
  }

  // Closing the WebSocket connection
  const closeWebSocket = () => {    
    if (webSocketRef.current  && webSocketRef.current.readyState === WebSocket.OPEN) {
      console.log('Closing webSocket...');
      webSocketRef.current.close();
      webSocketRef.current = null;      
    }
  };

  const updateUserTokens = async () => {
    try {    
      if(isCurrentlyUserLoggedIn() == false) return; //When a user is not logged in there is no tokens to get from the server

      const loggedIn = await userService.getLoggedInUser();
      setloggedInUser(loggedIn);

      if (loggedIn) {
        setTotalQuota(loggedIn.quota);
        setLeftsQuota(loggedIn.search_count);
        console.log(`User search quota: ${loggedIn.search_count} from ${loggedIn.quota}`);
      } else {
        setTotalQuota(0);
        setLeftsQuota(0);
      }
    } catch (error) {
      console.error("Failed to update user quta:", error);
    }
  };

  const isCurrentlyUserLoggedIn = () =>{
    const userName = localStorage.getItem("userName");
    const userEmail = localStorage.getItem("userEmail");

    // Redirect to login if no user data is present
    if (!userName && !userEmail) {      
      return false;
    }    
    return true;
  }

  //Cleaning the Provider - making sure the webSoket is close
  useEffect(() => {
    return () => {
      closeWebSocket();
    };
  }, []);

  
  return (
    <StatusUpdatesContext.Provider value={{      
      activeSearchesRecord,            
      userSearchTotalQuota,
      userSearchLeftsQuota,
      loggedInUser,
      initiateStatusUpdates,
      }}
    >
      {children}
    </StatusUpdatesContext.Provider>
  );
};