import React, { useState, useContext, useEffect, useMemo } from "react";
import { Container, Typography, Box, CircularProgress } from "@mui/material";
import "react-day-picker/dist/style.css";
import { userService } from "../services/user.service";
import { showErrorMsg } from "../services/event-bus.service";
import PatentValidator from "../services/validation.service";
import { searchService } from "../services/search.service";
import { StatusUpdatesContext } from "../services/search.status.updates.context";
import PreSearchAssigneeList from "../components/pre-search-assignee/pre-search-assignee-list";
import ConfirmCreditForSearch from "../components/pre-search/confirmCreditForSearch";
import PreSearchAssigneesForm from "../components/pre-search-assignee/pre-search-assignee-form";
import PreSearchAssigneesControls from "../components/pre-search-assignee/pre-search-assignees-control";

const PreSearchAssignees = () => {
  // Form  assignee search states
  const [searchAssignee, setSearchAssignee] = useState({
    query: "",
    assignee: "",
    startDate: null,
    endDate: null,
    showStartDatePicker: false,
    showEndDatePicker: false,
  });

  // List management states
  const [assignees, setAssignees] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  // Search processing states
  const [isLoading, setIsLoading] = useState(false);
  const [validPatents, setValidPatents] = useState([]);
  const [searchAvailable, setSearchAvailable] = useState(0);
  const [sumConfirmPatents, setSumConfirmPatents] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedClaim, setSelectedClaim] = useState(0);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { initiateStatusUpdates } = useContext(StatusUpdatesContext);
  const filteredAssignees = useMemo(() => {
    const searchTermLower = searchTerm.toLowerCase();
    return assignees.filter(
      (item) =>
        item.assignee.toLowerCase().includes(searchTermLower) ||
        item.title.toLowerCase().includes(searchTermLower) ||
        item.publication_number.toLowerCase().includes(searchTermLower)
    );
  }, [searchTerm, assignees]);
  const handleAssigneeChange = (field, value) => {
    setSearchAssignee((prevSearch) => ({
      ...prevSearch,
      [field]: value,
    }));
  };
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset page directly when search term changes
  };
  const handleSubmit = async () => {
    setIsFormSubmitted(true);

    try {
      setIsLoading(true);
      setSearchTerm("");
      setSelectedItems([]);
      setCurrentPage(1);
      setValidPatents([]);
      setShowConfirm(false);
      const formatDate = (date) => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}${month}${day}`;
      };

      const formattedSearch = {
        query: searchAssignee.query,
        assignee: searchAssignee.assignee,
        before: formatDate(searchAssignee.endDate),
        after: formatDate(searchAssignee.startDate),
      };

      const results = await userService.companySearchPatents(formattedSearch);
      setSearchAssignee({
        query: "",
        assignee: "",
        startDate: null,
        endDate: null,
        showStartDatePicker: false,
        showEndDatePicker: false,
      });
      setAssignees(results);
      setIsFormSubmitted(false);
    } catch (error) {
      console.error("Failed to submit search:", error);
      showErrorMsg("Failed to fetch results");
    } finally {
      setIsLoading(false);
    }
  };

  // List management handlers

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleCheckboxChange = (publication_number) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(publication_number)
        ? prevSelected.filter((item) => item !== publication_number)
        : [...prevSelected, publication_number]
    );
  };

  // Search selected processing handlers
  const handleSearchSelectedPatentClick = async () => {
    if (selectedItems.length === 0) {
      showErrorMsg("No items selected. Please select at least one item.");
      return;
    }

    setIsLoading(true);

    const { validPatents: validatedPatents, invalidPatents } =
      PatentValidator.validatePatents(selectedItems.join(", "));

    if (validatedPatents.length > 20) {
      showErrorMsg("You can only search for 20 patents at a time.");
      setIsLoading(false);
      return;
    }

    if (invalidPatents.length > 0) {
      showErrorMsg(
        "Please check the following invalid patents: " +
          invalidPatents.join(", ")
      );
      setIsLoading(false);
      return;
    }

    if (validatedPatents.length > 0 && invalidPatents.length === 0) {
      setValidPatents(validatedPatents);

      try {
        const validData = await searchService.validatePatent(validatedPatents);
        setSearchAvailable(validData?.searches_available);

        const inValidData = validData?.patents
          .filter((patent) => patent.is_valid === false)
          .map((patent) => patent.patent_number);

        if (inValidData.length > 0) {
          showErrorMsg(
            `Failed to validate patents. These are the invalid patents: ${inValidData.join(
              ", "
            )}`
          );
          setIsLoading(false);
          return;
        }

        const sumConfirmPatents =
          selectedClaim === 1
            ? validData?.patents.length
            : validData?.patents.reduce(
                (acc, curr) => acc + (curr.claims || 0),
                0
              );

        setSumConfirmPatents(sumConfirmPatents);
        setShowConfirm(true);
      } catch (error) {
        console.error("Error during patent validation:", error);
        showErrorMsg(
          "An error occurred during validation. Please try again later."
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleConfirmSearch = async () => {
    setShowConfirm(false);
    setIsLoading(true);

    try {
      await searchService.startSearch(
        validPatents,
        initiateStatusUpdates,
        selectedClaim
      );
    } catch (error) {
      console.error("Error during search:", error);
      showErrorMsg("Failed to start search.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };

  // Pagination calculations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAssignees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const pageCount = Math.ceil(filteredAssignees.length / itemsPerPage);
  const handleDatePickerToggle = (field) => {
    setSearchAssignee((prev) => ({
      ...prev,
      showStartDatePicker:
        field === "showStartDatePicker" ? !prev.showStartDatePicker : false,
      showEndDatePicker:
        field === "showEndDatePicker" ? !prev.showEndDatePicker : false,
    }));
  };
  return (
    <Container sx={{ marginTop: "100px" }}>
      {/* Sticky Container for Form and Controls */}
      <Box
        sx={{
          position: "sticky",
          top: "100px",
          zIndex: 900,
          backgroundColor: "white",
        }}
      >
        {/* Form assignee Section */}
        <PreSearchAssigneesForm
          search={searchAssignee}
          handleChange={handleAssigneeChange}
          handleDatePickerToggle={handleDatePickerToggle}
          handleSubmit={handleSubmit}
          isFormSubmitted={isFormSubmitted}
        />

        {/* Search Controls - Only shown when there are results */}
        {filteredAssignees.length > 0 && (
          <PreSearchAssigneesControls
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            itemsPerPage={itemsPerPage}
            handleItemsPerPageChange={handleItemsPerPageChange}
            pageCount={pageCount}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
            handleSearchSelectedPatentClick={handleSearchSelectedPatentClick}
            selectedItems={selectedItems}
            handleSearchTermChange={handleSearchTermChange}
          />
        )}
      </Box>

      {/* List Section */}
      {isLoading ? (
        <Box
          sx={{
            marginTop: "200px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress sx={{ color: "primary.main" }} />
          <Typography variant="h6" sx={{ marginTop: "10px", color: "gray" }}>
            Almost there...
          </Typography>
        </Box>
      ) : (
        <PreSearchAssigneeList
          items={currentItems}
          selectedItems={selectedItems}
          onCheckboxChange={handleCheckboxChange}
        />
      )}

      {/* Confirmation Dialog */}
      {showConfirm && (
        <ConfirmCreditForSearch
          credit={sumConfirmPatents}
          searchAvailable={searchAvailable}
          onConfirm={handleConfirmSearch}
          onClose={handleCloseConfirm}
        />
      )}
    </Container>
  );
};

export default PreSearchAssignees;
