// Create a new file: components/search-form/AssigneesSearchForm.jsx
import React from "react";
import { TextField, Typography, Button, Box } from "@mui/material";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const PreSearchAssigneesForm = ({
  search,
  handleChange,
  handleDatePickerToggle,
  handleSubmit,
  isFormSubmitted,
}) => {
  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        width: "100%",
        padding: "30px 0 20px 0",
      }}
    >
      <TextField
        label="Assignee"
        value={search.assignee}
        onChange={(e) => handleChange("assignee", e.target.value)}
        variant="outlined"
        size="small"
        required
        error={isFormSubmitted && !search.assignee.trim()}
        sx={{ minWidth: "150px" }}
      />
      <TextField
        label="Search Terms"
        value={search.query}
        onChange={(e) => handleChange("query", e.target.value)}
        variant="outlined"
        size="small"
        sx={{ minWidth: "150px" }}
      />

      <Box sx={{ position: "relative" }}>
        <TextField
          label="Publication Start Date"
          value={search.startDate ? search.startDate.toLocaleDateString() : ""}
          onClick={() => handleDatePickerToggle("showStartDatePicker")}
          variant="outlined"
          size="small"
          readOnly
          required
          error={isFormSubmitted && !search.startDate}
          sx={{ minWidth: "150px" }}
        />
        {search.showStartDatePicker && (
          <Box
            sx={{
              position: "absolute",
              top: "calc(100% + 8px)",
              left: 0,
              zIndex: 1000,
              backgroundColor: "white",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: 1,
              padding: "16px",
              mt: 1,
            }}
          >
            <DayPicker
              mode="single"
              startMonth={new Date(2000, 0)}
              endMonth={new Date(2028, 11)}
              captionLayout="dropdown"
              selected={search.startDate}
              onSelect={(date) => {
                handleChange("startDate", date);
                handleChange("showStartDatePicker", false);
                handleChange("endDate", null);
              }}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ position: "relative" }}>
        <TextField
          label="Publication End Date"
          value={search.endDate ? search.endDate.toLocaleDateString() : ""}
          onClick={() => handleDatePickerToggle("showEndDatePicker")}
          variant="outlined"
          size="small"
          readOnly
          required
          error={isFormSubmitted && !search.endDate}
          sx={{ minWidth: "150px" }}
        />
        {search.showEndDatePicker && (
          <Box
            sx={{
              position: "absolute",
              top: "calc(100% + 8px)",
              left: -100,
              zIndex: 1000,
              backgroundColor: "white",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: 1,
              padding: "16px",
              mt: 1,
            }}
          >
            <DayPicker
              mode="single"
              startMonth={new Date(2000, 0)}
              endMonth={new Date(2028, 11)}
              captionLayout="dropdown"
              selected={search.endDate}
              disabled={{ before: search.startDate || new Date() }}
              onSelect={(date) => {
                handleChange("endDate", date);
                handleChange("showEndDatePicker", false);
              }}
            />
          </Box>
        )}
      </Box>
      <Button
        disabled={!(search.assignee && search.startDate && search.endDate)}
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        sx={{
          padding: "8px 16px",
          color: "white",
          fontSize: "12px",
        }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default PreSearchAssigneesForm;
