import React from "react";
import { Box, InputBase, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PaginationFormControl from "../pagination-control";
import CheckIcon from "@mui/icons-material/Check"; // Add this import

const PreSearchAssigneesControls = ({
  searchTerm,
  setSearchTerm,
  itemsPerPage,
  handleItemsPerPageChange,
  pageCount,
  currentPage,
  handleChangePage,
  handleSearchSelectedPatentClick,
  selectedItems,
  handleSearchTermChange,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 0 20px 0",
        borderBottom: "1px solid #ccc",
        gap: 1,
      }}
    >
      {/* Left: Selected items action */}
      {selectedItems.length > 0 && (
        <Button
          variant="contained"
          onClick={handleSearchSelectedPatentClick}
          startIcon={<CheckIcon />}
          size="small"
          sx={{
            padding: "8px 6px",
            fontSize: "0.75rem",
            minWidth: "auto",
            lineHeight: "1.2",
            // borderRadius: "12px",
            textTransform: "none",
          }}
        >
          Search {selectedItems.length} <br />
          Selected Patent
          {selectedItems.length > 1 ? "s" : ""}
        </Button>
      )}

      {/* Middle: Search input */}
      <Box sx={{ position: "relative", width: "200px" }}>
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
          }}
        >
          <SearchIcon />
        </Box>
        <InputBase
          placeholder="Search ..."
          inputProps={{ "aria-label": "search" }}
          sx={{ paddingLeft: "40px", width: "100%" }}
          value={searchTerm}
          onChange={handleSearchTermChange}
        />
      </Box>

      {/* Right: Pagination */}
      <PaginationFormControl
        itemsPerPage={itemsPerPage}
        handleItemsPerPageChange={handleItemsPerPageChange}
        pageCount={pageCount}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
      />
    </Box>
  );
};

export default PreSearchAssigneesControls;
