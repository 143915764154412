import React from "react";
import {
  Typography,
  Box,
  List,
  Tooltip,
  CircularProgress,
  Button,
} from "@mui/material";

import { utilsService } from "../../services/utils";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
function ArchiveList({
  currentItems,
  isSmallScreen,
  handleOpenPatent,
  expandedItems,
  setExpandedItems,
}) {
  return (
    <>
      {currentItems.length > 0 ? (
        <List sx={{ marginTop: "60px" }}>
          {currentItems.map((item, index) => (
            <Box
              className="item-box"
              key={index}
              style={{
                padding: "15px 0",
                borderBottom: "1px solid #e9e8e8",
                ...(isSmallScreen ? { display: "block" } : { display: "flex" }),
              }}
            >
              {isSmallScreen && item.thumbnail && (
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "10px",
                    backgroundColor: "gray",
                  }}
                >
                  <img
                    src={item.thumbnail}
                    alt="patent-img"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                      display: "block",
                    }}
                  />
                </div>
              )}
              {!isSmallScreen && item.thumbnail && (
                <div
                  style={{
                    width: "120px",
                    height: "120px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "20px",
                    borderRadius: "5px",
                    border: "1px solid rgb(204, 204, 204, 0.2)",
                  }}
                >
                  <img
                    src={item.thumbnail}
                    alt="patent-img"
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                      display: "block",
                    }}
                  />
                </div>
              )}
              {!item.thumbnail && (
                <Box
                  sx={{
                    width: isSmallScreen ? "60%" : "80px",
                    height: isSmallScreen ? "320px" : "160px",
                    maxWidth: isSmallScreen ? "280px" : "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                    color: "gray",
                    border: "1px solid rgb(204, 204, 204, 0.2)",
                    borderRadius: "5px",
                    marginRight: isSmallScreen ? "auto" : "20px",
                    marginLeft: isSmallScreen ? "auto" : "0",
                    marginBottom: isSmallScreen ? "20px" : "none",
                    textAlign: "center",
                    fontSize: "14px",
                    padding: "20px",
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "14px" }}>
                    No data
                  </Typography>
                </Box>
              )}
              <Box
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                <Box
                  style={{
                    display: isSmallScreen ? "" : "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "primary.main", // Apply custom text color to the Link
                    }}
                    fontWeight="bold"
                  >
                    <span>{item.query.patent_number}</span>
                    {Object.values(item.claims || []).map((claim, index) =>
                      claim && claim.index ? (
                        <span
                          style={{ color: "lightgray", marginLeft: "5px" }}
                          key={claim.index}
                        >
                          |{" "}
                          <Tooltip
                            title={
                              !claim.status || claim.status === "completed"
                                ? "Click to show results"
                                : `${claim.status}`
                            }
                          >
                            <span
                              onClick={
                                !claim.status || claim.status === "completed"
                                  ? () =>
                                      handleOpenPatent(
                                        item.search_id,
                                        item.status,
                                        claim.index
                                      )
                                  : null
                              }
                              style={{
                                cursor:
                                  !claim.status || claim.status === "completed"
                                    ? "pointer"
                                    : "not-allowed",
                                textDecoration:
                                  !claim.status || claim.status === "completed"
                                    ? "underline"
                                    : "none",
                              }}
                            >
                              <span style={{ fontSize: "11px", color: "gray" }}>
                                {/* Claim {claim.index} */}
                                {claim.name}
                                {claim.status &&
                                  claim.status !== "completed" && (
                                    <ErrorOutlineIcon
                                      style={{
                                        fontSize: "14px",
                                        color: "red",
                                        marginLeft: "1px",
                                      }}
                                    />
                                  )}
                              </span>{" "}
                            </span>
                          </Tooltip>
                        </span>
                      ) : null
                    )}
                  </Typography>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body2"
                      sx={{ color: "gray" }}
                      style={{ marginRight: "8px" }}
                    >
                      ( {utilsService.formatDate(item.updated_at)} )
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color:
                          item.status === "completed"
                            ? "green"
                            : item.status === "running"
                            ? "rgb(25, 118, 210)" // Blue for running
                            : item.status === "failed"
                            ? "red" // Red for failed
                            : "black", // Black for other statuses
                      }}
                    >
                      {item.status === "running" ? (
                        <>
                          {item.status} <CircularProgress size={15} />
                        </>
                      ) : (
                        item.status
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: "gray",
                    display: "-webkit-box",
                    WebkitLineClamp: expandedItems[index] ? "none" : 2, // Control the number of lines
                    WebkitBoxOrient: "vertical",
                    overflow: expandedItems[index] ? "visible" : "hidden", // Hide overflow if not expanded
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    color: "gray",
                    display: expandedItems[index] ? "block" : "none", // Show full description or hide it
                    overflow: expandedItems[index] ? "visible" : "hidden", // Ensure proper handling of content
                  }}
                >
                  {item.description}
                </Typography>

                {/* Button to toggle description */}
                <Box sx={{ mt: 1 }}>
                  {item.description && (
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        fontSize: "0.75rem",
                        padding: "2px 8px",
                        minWidth: "unset",
                      }}
                      onClick={() => {
                        setExpandedItems((prev) => ({
                          ...prev,
                          [index]: !prev[index],
                        }));
                      }}
                    >
                      {expandedItems[index]
                        ? "Close Abstract"
                        : "Show Abstract"}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </List>
      ) : (
        <Typography variant="h6" textAlign="center" sx={{ mt: 2 }}>
          No search history found.
        </Typography>
      )}
    </>
  );
}
export default ArchiveList;
