import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import { eventBus, SHOW_MSG } from "../services/event-bus.service";

export function UserMsg() {
  const [msg, setMsg] = useState(null);
  const timeoutIdRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = eventBus.on(SHOW_MSG, (msg) => {
      setMsg(msg);
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(closeMsg, 13000);

      if (msg.txt.includes("401")) {
        navigate("/");
      }
    });

    return () => {
      unsubscribe();
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [navigate]);

  function closeMsg() {
    setMsg(null);
  }

  if (!msg) return null;

  return (
    <Snackbar
      open={Boolean(msg)}
      autoHideDuration={13000}
      onClose={closeMsg}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert
        onClose={closeMsg}
        severity={msg.type === "success" ? "success" : "error"}
        sx={{ width: "100%" }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: msg.txt.replace(
              /<a href="([^"]+)">([^<]+)<\/a>/g,
              `<a href="$1" style="color: #007bff; text-decoration: underline;">$2</a>`
            ),
          }}
        />
      </Alert>
    </Snackbar>
  );
}
