import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Snackbar, Alert } from "@mui/material";
import { searchService } from "../services/search.service";
import { StatusUpdatesContext } from "./../services/search.status.updates.context";
import PatentSearchControls from "../components/patent-product/patent-search-controls"; // Import the new component

const PatentProductIndex = () => {
  const [dataSearchId, setDataSearchId] = useState("");
  const [searchProductTerm, setSearchProductTerm] = useState("");
  const [PatentProducts, setPatentProducts] = useState([]);
  const [AllPatentProducts, setAllPatentProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [patentInfo, setPatentInfo] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [error, setError] = useState("");
  const [querySearch, setQuerySearch] = useState("");
  const [claimsIndexes, setClaimsIndexes] = useState([]);
  const [updatedAt, setUpdatedAt] = useState("");
  const [status, setStatus] = useState("");
  const { activeSearchesRecord } = useContext(StatusUpdatesContext);
  const location = useLocation();

  const params = useParams();
  const queryParams = new URLSearchParams(location.search);

  const [choosenClaimIndex, setChoosenClaimIndex] = useState(
    queryParams.get("claimIndex")
      ? parseInt(queryParams.get("claimIndex"), 10)
      : 1
  );

  const archiveId = params.archiveId;
  const searchStatus = queryParams.get("status");
  const storageToken = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    if (!storageToken) {
      navigate("/");
    }
  }, [storageToken]);
  useEffect(() => {
    const userName = localStorage.getItem("userName");
    const userEmail = localStorage.getItem("userEmail");

    if (!userName && !userEmail) {
      navigate("/");
    }
    if (archiveId) {
      setLoading(true);
      handleOpenPatent(archiveId, searchStatus);
    }
  }, [navigate, archiveId, searchStatus]);

  useEffect(() => {
    if (archiveId) {
      handleOpenPatent(archiveId, searchStatus);
    }
  }, [choosenClaimIndex]);

  //Dynamicly update's view while search status is running
  useEffect(() => {
    console.log(`Updating the the tabs in the patent view`);
    const fetchData = async () => {
      await searchService.getPatentResults(
        archiveId,
        (results) => {
          console.log(
            `Updating the the tabs in the patent view, Results arived - updating UI elements`
          );
          UpdateUiElementsFromResults(results);
        },
        (errorMessage) => {
          console.error(
            "Failed to fetch results for patent ID:",
            archiveId,
            errorMessage
          );
          setError("Failed to fetch results for patent ID.");
        }
      );
    };
    fetchData();
  }, [activeSearchesRecord]);

  const handleOpenPatent = async (archiveId, searchStatus) => {
    if (searchStatus === "failed") {
      setError(
        "Search failed. Please try again with a different search query."
      );
      setLoading(false);
      return;
    }

    setLoading(true);

    await searchService.getPatentResults(
      archiveId,
      (results) => {
        UpdateUiElementsFromResults(results);
        setLoading(false);
      },
      (errorMessage) => {
        console.error(
          "Failed to fetch results for patent ID:",
          archiveId,
          errorMessage
        );
        setError("Failed to fetch results for patent ID.");
        setLoading(false);
      }
    );
  };

  function UpdateUiElementsFromResults(results) {
    if (results?.claims?.[choosenClaimIndex]?.results) {
      let sortedResults = results.claims[choosenClaimIndex].results;
      // Sort results by rating (liked ratings first, disliked ratings last)
      sortedResults.sort((a, b) => {
        if (a.rating === 1 && b.rating !== 1) return -1;
        if (b.rating === 1 && a.rating !== 1) return 1;
        if (a.rating === -1 && b.rating !== -1) return 1;
        if (b.rating === -1 && a.rating !== -1) return -1;
        if (a.rating === b.rating) {
          return b.rating_auto - a.rating_auto; // Secondary sort by rating_auto
        }
        return b.rating - a.rating;
      });

      setPatentProducts(sortedResults);
      setAllPatentProducts(sortedResults);
      setQuerySearch(results.query?.patent_number);
      setPatentInfo(results.patent);
      setUpdatedAt(results.updated_at);
      setStatus(results.status);
      setDataSearchId(results._id.$oid);
      console.log("RESULTS!!", results);
      const claimsKeysArray = Object.keys(results.claims);
      console.log("claimsKeysArray***", claimsKeysArray);
      const resultClaimsArray = claimsKeysArray.map((key) => ({
        claimIndex: parseInt(key, 10),
        claim_text: results.claims[key].claim_text,
        status: results.claims[key].status,
        name: results.claims[key].name,
      }));
      setClaimsIndexes(resultClaimsArray);
    } else {
      console.log("Claims or results not found in response:", results);
      setError("No results found for this patent ID.");
    }
  }

  return (
    <Container maxWidth="lg">
      <PatentSearchControls
        claimsIndexes={claimsIndexes}
        choosenClaimIndex={choosenClaimIndex}
        setChoosenClaimIndex={setChoosenClaimIndex}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        searchProductTerm={searchProductTerm}
        setSearchProductTerm={setSearchProductTerm}
        PatentProducts={PatentProducts}
        setPatentProducts={setPatentProducts}
        AllPatentProducts={AllPatentProducts}
        setAllPatentProducts={setAllPatentProducts}
        loading={loading}
        setLoading={setLoading}
        error={error}
        setError={setError}
        patentInfo={patentInfo}
        setPatentInfo={setPatentInfo}
        querySearch={querySearch}
        setQuerySearch={setQuerySearch}
        dataSearchId={dataSearchId}
        setDataSearchId={setDataSearchId}
        updatedAt={updatedAt}
        status={status}
      />
    </Container>
  );
};

export default PatentProductIndex;
