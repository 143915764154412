import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';

const CreateEditUser = ({ open, onClose, onSave, user, handleChange }) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (user) {
      console.log("edit user", user);
      setErrors({});
    }
  }, [user]);

  const validate = () => {
    let tempErrors = {};
    if (!user.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)) {
      tempErrors.email = 'Please enter a valid email address';
    }
    if (user.quota < 0) {
      tempErrors.quota = 'Quota must be 0 or greater';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
      onSave();
    }
  };

  const handleQuotaChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value >= 0) {
      handleChange(event);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{user && user._id ? 'Edit User' : 'Add User'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {user && user._id ? 'To edit the user, please modify the fields below.' : 'To add a new user, please fill out the fields below.'}
        </DialogContentText>
        <TextField
          autoFocus

          margin="dense"
          name="email"
          label="Email"
          type="email"
          fullWidth
          value={user ? user.email : ''}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          margin="dense"
          name="first"
          label="First Name"
          type="text"
          fullWidth
          value={user ? user.first : ''}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="last"
          label="Last Name"
          type="text"
          fullWidth
          value={user ? user.last : ''}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="company"
          label="Company"
          type="text"
          fullWidth
          value={user ? user.company : ''}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="quota"
          label="Quota"
          type="number"
          fullWidth
          value={user ? user.quota : ''}
          onChange={handleQuotaChange}
          error={!!errors.quota}
          helperText={errors.quota}
          inputProps={{ min: 0 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={user ? user.admin : false}
              onChange={(e) => handleChange({ target: { name: 'admin', value: e.target.checked } })}
              name="admin"
              color="primary"
            />
          }
          label="Admin"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditUser;
